@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";

/**
 * @file
 * Utility classes to hide elements in different ways.
 */

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be collapsible details that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
 .hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen readers.
 *
 * Used for information required for screen reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

/**
 * The .focusable class extends the .visually-hidden class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

/**
 * Hide visually and from screen readers, but maintain layout.
 */
.invisible {
  visibility: hidden;
}

.path-topics {
  .article {
    max-width: 1000px;
    margin: 20px auto 80px;
    .article-list {
      display: flex;
      gap: 12px 12px;
      flex-wrap: wrap;
      margin-left: 10px;
      &__item {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 172px;
        margin-bottom: 24px;
        a {
          color: $GRAY_700;
          figure {
            img {
              object-fit: cover;
              width: 100%;
              height: 98px;
              vertical-align: bottom;
            }
          }
          .category {
            margin-top: 4px;
            text-align: center;
            color: #FFF;
            font-size: 12px;
            padding: 2px 0;
            &.live {
              background: $COLOR_ORANGE;
            }
            &.information {
              background: $COLOR_MAIN;
            }
            &.special {
              background: $COLOR_RED;
            }
          }
          .schedule {
            margin-top: 4px;
            font-size: 14px;
            font-weight: 700;
            &::before {
              content: "";
              display: inline-block;
              background: url(/img/top/topics_schedule.svg) top left  no-repeat;
              width: 17px;
              padding-top: 18px;
              margin-bottom: -3px;
              margin-right: 4px;
            }
          }
          .txt {
            margin-top: 4px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .pager__items {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 80px;
    text-align: center;
    .pager__item {
      margin: 0 4px;
      a {
        display: block;
        padding: 8px 16px;
        font-size: 16px;
        &:hover {
          background-color: $COLOR_MAIN;
          color: $GRAY_000;
        }
      }
      &.is-active {
        background-color: $COLOR_MAIN;
        a {
          color: $GRAY_000;
        }
      }
      &--ellipsis {
        display: none;
      }
      &--next {
        a {
          display: block;
          background: url(/img/top/arrow_r.svg) top left  no-repeat;
          width: 19px;
          padding-top: 42px;
          margin-left: 8px;
          &:hover {
            background: url(/img/top/arrow_r.svg) top left  no-repeat;
          }
        }
      }
      &--last {
        display: none;
      }
      &--previous {
        a {
          display: block;
          background: url(/img/top/arrow_l.svg) top left  no-repeat;
          width: 19px;
          padding-top: 42px;
          margin-right: 8px;
          &:hover {
            background: url(/img/top/arrow_l.svg) top left  no-repeat;
          }
        }
      }
      &--first {
        display: none;
      }
    }
  }
}



/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .path-topics {
    .article {
      .article-list {
        gap: 12px 20px;
        margin-left: 0;
        &__item {
          flex-basis: 235px;
          flex-shrink: 0;
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          margin-bottom: 40px;
          a {
            figure {
              overflow: hidden;
              img {
                object-fit: cover;
                width: 100%;
                height: 133px;
                vertical-align: bottom;
              }
            }
            .category {
              margin-top: 8px;
              padding: 4px 0;
            }
            .schedule {
              margin-top: 8px;
            }
            .txt {
              margin-top: 8px;
              font-size: 16px;
            }
            &:hover {
              figure {
                img {
                  filter: brightness(70%);
                  transform: scale(1.1,1.1);
                }
              }
              .txt {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
